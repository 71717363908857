<template>
  <orderCardItem :data="data"/>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";

export default {
  mixins: [listItem],
  components: {
    orderCardItem: () => import("@/modules/provider/views/backDetail/BackListComponents/orderCardItem.vue"),
  },
};
</script>